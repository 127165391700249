import { Link } from 'react-router-dom';
import styles from './Topbar.module.scss';

const Topbar = () => (
    <div className={styles.topbar}>
        <div className={styles.topbar_content}>
            <div>
                <img src='/assets/icon/hostai_logo.svg' alt='hostai logo' />
            </div>
            <div className={styles.topbar_content_buttons}>
                <div className={styles.tobpar_links}>
                    <a href='/#how_it_work'>How it works</a>
                    <a href='/#testimonals'>Testimonials</a>
                    {/* <a href="/#about_us">About Us</a> */}
                </div>
                <div>
                    <Link to='https://ksiacb3d3sj.typeform.com/to/jqXVXlJn'>
                        <button className={styles.tobpar_button}>
                            Join Waitlist
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
);

export default Topbar;
