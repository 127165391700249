import styles from './Testimonial.module.scss';

const Testimonial = () => (
    <div className={styles.testimonial} id='testimonals'>
        <div className={styles.testimonial_title}>What our customers say</div>
        <div className={styles.testimonial_content}>
            <div className={styles.testimonial_content_desc}>
                “We signed up for hostAI when we kicked off our focused effort
                on direct revenue. Within weeks we saw impressive growth. The
                team is professional, proactive and responsive -- making the
                experience effortless and stress-free. I’d highly recommend
                hostAI to any vacation rental manager looking to grow their
                direct revenue”
            </div>
            <div className={styles.testimonial_content_user}>
                <div
                    className={styles.testimonial_content_user_imgIcon}
                    style={{ backgroundImage: 'url(/assets/img/tony.png)' }}
                ></div>
                <div className={styles.testimonial_content_user_desc}>
                    <div className={styles.testimonial_content_user_name}>
                        Tony
                    </div>
                    <div className={styles.testimonial_content_user_role}>
                        Founder, Blue Maple
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.testimonial_content}>
            <div className={styles.testimonial_content_desc}>
                “If you are a VRM, we encourage you to sign up and receive more
                info from hostAI when they come out of beta”
            </div>
            <div className={styles.testimonial_content_user}>
                <div
                    className={styles.testimonial_content_user_imgIcon}
                    style={{ backgroundImage: 'url(/assets/img/brad.png)' }}
                ></div>
                <div className={styles.testimonial_content_user_desc}>
                    <div className={styles.testimonial_content_user_name}>
                        Brad
                    </div>
                    <div className={styles.testimonial_content_user_role}>
                        Founder, Open Air Homes
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Testimonial;
