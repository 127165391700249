import {
    faCrown,
    faRocket,
    faSparkles,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Why.module.scss';

const Why = () => (
    <div className={styles.why_wrapper}>
        <div className={styles.why}>
            <div className={styles.why_title}>
                Why half of your bookings should be direct
            </div>
            <div className={styles.why_content}>
                <div className={styles.why_content_desc}>
                    <div className={styles.why_content_desc_btn}>
                        <FontAwesomeIcon icon={faRocket} />
                    </div>
                    <div className={styles.why_content_desc_title}>
                        Get ahead of competition
                    </div>
                    <div className={styles.why_content_desc_msg}>
                        A competitive advantage to grow your share in a tougher
                        2023 market.
                    </div>
                </div>
                <div className={styles.why_content_desc}>
                    <div className={styles.why_content_desc_btn}>
                        <FontAwesomeIcon icon={faSparkles} />
                    </div>
                    <div className={styles.why_content_desc_title}>
                        Distinguish your brand
                    </div>
                    <div className={styles.why_content_desc_msg}>
                        Grow your direct revenue and earn higher management
                        fees.
                    </div>
                </div>
                <div className={styles.why_content_desc}>
                    <div className={styles.why_content_desc_btn}>
                        <FontAwesomeIcon icon={faCrown} />
                    </div>
                    <div className={styles.why_content_desc_title}>
                        Become independent
                    </div>
                    <div className={styles.why_content_desc_msg}>
                        Reduce dependence on a single channel, and protect
                        yourself from algorithm changes and policy shift.
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Why;
