import styles from './HowItWork.module.scss';

const HowItWork = () => (
    <div className={styles.how} id='how_it_work'>
        <div className={styles.how_header}>
            <div className={styles.how_header_title}>How HostAI works</div>
            <div className={styles.how_header_desc}>
                Our AI analyzes millions of data points to develop a tailored
                marketing strategy just for you. From there, it automatically
                acquires new leads for you with targeted ads and converts them
                into your guests with optimized landing pages. Sit back, relax,
                and watch your direct revenue soar with hostAI!
            </div>
        </div>
        <div className={styles.how_content}>
            <div className={styles.how_content_img}>
                <img src='/assets/img/how_1.png' alt='how it works step 1' />
            </div>
            <div className={styles.how_content_card}>
                <button className={styles.how_content_card_numBtn}>1</button>
                <div className={styles.how_content_card_title}>
                    Quick Import
                </div>
                <div className={styles.how_content_card_desc}>
                    Connect your PMS and our AI will handle the rest. We will
                    automatically collect everything there is to know about your
                    portfolio so we understand exactly what attracts your
                    guests.
                </div>
            </div>
        </div>
        <div className={styles.how_content}>
            <div className={styles.how_content_img}>
                <img src='/assets/img/how_2.png' alt='how it works step 2' />
            </div>
            <div className={styles.how_content_card}>
                <button className={styles.how_content_card_numBtn}>2</button>
                <div className={styles.how_content_card_title}>
                    Smart Strategy
                </div>
                <div className={styles.how_content_card_desc}>
                    Our AI then crunches millions of data points to craft a
                    tailored marketing strategy for your vacation rental
                    portfolio. It’ll identify your ideal customers and how they
                    search for vacation rentals
                </div>
            </div>
        </div>
        <div className={styles.how_content}>
            <div className={styles.how_content_img}>
                <img src='/assets/img/how_3.png' alt='how it works step 3' />
            </div>
            <div className={styles.how_content_card}>
                <button className={styles.how_content_card_numBtn}>3</button>
                <div className={styles.how_content_card_title}>
                    Tailored Generation
                </div>
                <div className={styles.how_content_card_desc}>
                    And now the magic happens. Our AI creates personalized
                    landing pages and ad campaigns that showcase your attractive
                    attributes to your ideal customers. It's the most
                    conversion-optimized booking process available to Vacation
                    Rental Managers.
                </div>
            </div>
        </div>
        <div className={styles.how_content}>
            <div className={styles.how_content_img}>
                <img src='/assets/img/how_4.png' alt='how it works step 4' />
            </div>
            <div className={styles.how_content_card}>
                <button className={styles.how_content_card_numBtn}>4</button>
                <div className={styles.how_content_card_title}>
                    Dynamic Optimization
                </div>
                <div className={styles.how_content_card_desc}>
                    Our AI keeps your campaigns ahead of the curve and your
                    direct revenue growing. It adjusts to competitive dynamics,
                    seasonality, portfolio changes, occupancy changes, and more!
                </div>
            </div>
        </div>
    </div>
);

export default HowItWork;
