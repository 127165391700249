import { Link } from 'react-router-dom';
import styles from './Hero.module.scss';

const Topbar = () => {
    return (
        <div className={styles.hero}>
            <div className={styles.hero_desc}>
                <p className={styles.hero_desc_title}>
                    Accelerate Your Direct Bookings with
                    <span> Cutting Edge AI</span>
                </p>
                <p className={styles.hero_desc_detail}>
                    Automated one-stop shop to boost direct revenue
                    effortlessly, profitably and reliably for Vacation Rental
                    Managers. And it’s free!
                </p>
                <div className={styles.hero_desc_buttons}>
                    <a href='/#how_it_work'>
                        <button className={styles.hero_desc_secondary_btn}>
                            Learn more
                        </button>
                    </a>
                    <Link to='https://ksiacb3d3sj.typeform.com/to/jqXVXlJn'>
                        <button className={styles.hero_desc_primary_btn}>
                            Join Waitlist
                        </button>
                    </Link>
                </div>
            </div>
            <div className={styles.hero_img_wrapper}>
                <img src='/assets/img/hero.png' alt='hostai hero' />
            </div>
        </div>
    );
};

export default Topbar;
