import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <div className={styles.footer_wrapper}>
            <div className={styles.footer}>
                <div className={styles.footer_content}>
                    <img src='/assets/icon/hostai_logo.svg' alt='hostai logo' />
                    <p>
                        AI-Powered Direct Revenue Growth for Vacation Rental
                        Managers
                    </p>
                    <Link to='https://ksiacb3d3sj.typeform.com/to/jqXVXlJn'>
                        <button className={styles.footer_content_primary_btn}>
                            Join Waitlist
                        </button>
                    </Link>
                </div>
                <div className={styles.footer_content}>
                    {/* <div className={styles.footer_content_title}>Clients</div>
          <Link to="https://bookhostai.com/openairhomes">Open Air Homes</Link>
          <Link to="https://bookhostai.com/stay_pittsburgh">
            Stay Pittsburgh
          </Link>
          <Link to="https://bookhostai.com/blue_maple">Blue Maples</Link> */}
                </div>
                <div className={styles.footer_content}>
                    <div className={styles.footer_content_title}>Company</div>
                    {/* <Link to="">Team</Link> */}
                    <Link to='https://share-docs.clickup.com/25578335/d/h/rcjuz-1500/22c88cb8f8caaed'>
                        Career
                    </Link>
                    <Link to='https://share-docs.clickup.com/25578335/d/h/rcjuz-2207/78219b00d5d2e71'>
                        Privacy Policy
                    </Link>
                    <Link to='https://share-docs.clickup.com/25578335/d/h/rcjuz-2227/3513355be586204'>
                        Legal Terms of Service
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
