import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Landing from './landing/Landing';

function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Landing></Landing>,
        },
        {
            path: '/aaaa',
            element: <div>aaaa</div>,
        },
    ]);

    return (
        <div className='App'>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
