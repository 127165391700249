import styles from './InfoSection.module.scss';

const InfoSection = () => (
    <div className={styles.info}>
        <div className={styles.info_content}>
            <p>
                Acquire, convert and retain your own <br />
                customers with AI-generated landing
                <br />
                pages and vacation rental ads.
            </p>
            <p className={styles.info_content_point}>
                Automated and completely free to use.
            </p>
        </div>
        <div className={styles.info_img_wrapper}></div>
    </div>
);

export default InfoSection;
