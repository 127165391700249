import { Component } from 'react';
import Topbar from './components/Topbar/Topbar';
import Hero from './components/Hero/Hero';
import InfoSection from './components/InfoSection/InfoSection';
import HowItWork from './components/HowItWork/HowItWork';
import Why from './components/Why/Why';
import Testimonial from './components/Testimonial/Testimonial';
import Footer from './components/Footer/Footer';

class Landing extends Component {
    componentDidMount(): void {
        if (window?.location?.hash && window?.location?.hash !== '#') {
            let section = document.querySelector(window.location.hash);
            section?.scrollIntoView?.();

            setTimeout(() => {
                section = document.querySelector(window.location.hash);
                section?.scrollIntoView?.();
            }, 100);
        }
    }

    render() {
        return (
            <div>
                <Topbar />
                <Hero />
                <InfoSection />
                <HowItWork />
                <Why />
                <Testimonial />
                <Footer />
            </div>
        );
    }
}

export default Landing;
